import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

interface Props {

}

export default function Innovation({ }: Props): ReactElement {
  return (
    <div className="wrapper">
      <div className="hero row">
        <div className="col-md-7 col-xs-12">
          <h1>Test {'&'} learn tracks to accelerate growth</h1>
          <p className="subheading">Case</p>
          <p className="text">How can we better address new and younger audiences?
        </p>
          <p className="text">
            At Aveve, we decided to launch one test {'&'} learn track for each of our categories garden, animal and
            baking with our Digital Squad.
            This would help us learn to address new and younger audiences while expanding our digital {'&'} innovation
            skillset.
        </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-7 col-xs-12">
          <p className="subheading">Process</p>
          <p className="text">
            Over our three tracks combined, the Digital Squad learned many techniques to go through the discovery, build
            and validation steps of the innovation process:
        <ul>
              <li>Stakeholder {'&'} customer interviews</li>
              <li>Insights gathering from market {'&'} internal data</li>
              <li>Executive pitching {'&'} scope signoff</li>
              <li>Briefing design partners</li>
              <li>Market scans</li>
              <li>Competitive steals</li>
              <li>Customer journey analysis {'&'} clustering</li>
              <li>Crazy eights</li>
              <li>Concept cards</li>
              <li>Decision dash</li>
              <li>Value proposition design</li>
              <li>Visual protoyping</li>
              <li>Campaign funnel creation {'&'} iteration</li>
              <ul>
                <li>Social campaign</li>
                <li>Landing page</li>
                <li>E-mail automation</li>
              </ul>
              <li>Concept validation</li>
            </ul>
          </p>
        </div>

        <div className="col-md-7 col-xs-12">
          <p className="subheading">Role: Team lead digital squad</p>
          <p className="text">Tasks:
        <ul>
              <li>Defining test {'&'} learn tracks</li>
              <li>Pitching to executives</li>
              <li>Briefing partners</li>
              <li>Insights gathering</li>
              <li>Co-creation</li>
              <li>Execute, learn, report {'&'} iterate</li>
            </ul>
          </p>
        </div>

        <div className="section">
          <div className="col-md-8 col-xs-12">
            <h2>Too much process, not enough insights {'&'} decisions? I agree.</h2>
            <p className="text">I’m not at liberty to share specific project details in this portfolio. I'd be happy to elaborate on the challenge, process, insights, decisions, outcomes and next steps when we meet..</p>
            <HashLink to="/about#contact" className="button">Contact me</HashLink>
          </div>
        </div>

      </div>
    </div>
  )
}

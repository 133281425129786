import React from 'react';
import Header from './Header';
import { Routes, Route, Navigate } from 'react-router-dom';
import About from './About';
import Home from './Home';
import Footer from './Footer';
import Strategy from './Strategy';
import UX from './UX';
import Innovation from './Innovation';

function App() {
  return (
    <div className="app-container">
      <Header />
      <main className="main-content">
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/cases/strategy" element={<Strategy />} />
          <Route path="/cases/ux" element={<UX />} />
          <Route path="/cases/innovation" element={<Innovation />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;

import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

interface Props {

}

export default function Strategy({ }: Props): ReactElement {
  return (
    <div className="wrapper">
      <div className="hero row">
        <div className="col-md-7 col-xs-12">
          <h1>Hybrid retail strategy and roadmap for Aveve</h1>
          <p className="subheading">Case</p>
          <p className="text">How might we set up an online strategy that will drive business to offline and back?</p>
          <p className="text">We defined Aveve's vision for a hybrid future and the transformation program to get there in a
          3-week strategy sprint. This set us on track to start developing our customer experience for profitable
          growth.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-7 col-xs-12">
          <p className="subheading">Process</p>
          <ul>
            <li>Stakeholder interviews</li>
            <li>Market scan</li>
            <li>North star vision</li>
            <li>Customer journey analysis</li>
            <li>Ideation</li>
            <li>Prioritisation</li>
            <li>Capabilities scan</li>
            <li>Gap analysis</li>
            <li>Roadmap definition</li>
            <li>Stakeholder commitment</li>
          </ul>
        </div>

        <div className="col-md-7 col-xs-12">
          <p className="subheading">Role: Digital Coordinator</p>
          <p className="text">Tasks:
            <ul>
              <li>Single point of contact</li>
              <li>Requirements gathering</li>
              <li>Data gathering &amp; analysis</li>
              <li>Partner briefing</li>
              <li>Scheduling</li>
              <li>Workshopping</li>
              <li>Execute strategy</li>
            </ul>
          </p>
        </div>

        <div className="section">
          <div className="col-md-8 col-xs-12">
            <h2>Too much process, not enough insights {'&'} decisions? I agree.</h2>
            <p className="text">I’m not at liberty to share specific project details in this portfolio. I'd be happy to elaborate on the challenge, process, insights, decisions, outcomes and next steps when we meet..</p>
            <HashLink to="/about#contact" className="button">Contact me</HashLink>
          </div>
        </div>

      </div>
    </div>
  )
}

import React, { ReactElement } from 'react';

interface Props {
  // Potentially add props here if needed
}

export default function Footer({ }: Props): ReactElement {
  return (
    <footer>
      <div className="footer">
        <ul>
          <li>&copy; 2024 Christophe van Eerdt</li>
          <li><a href="mailto:cvaneerdt@gmail.com" target="_blank" rel="noreferrer">cvaneerdt@gmail.com</a></li>
          <li><a href="tel:+32 479 70 63 42" target="_blank" rel="noreferrer">+32 479 70 63 42</a></li>
          <li><a href="https://www.linkedin.com/in/cvaneerdt/" target="_blank" rel="noreferrer">LinkedIn</a></li>
          <li>BE0 6677 95 609</li>
        </ul>
      </div>
    </footer>
  );
}

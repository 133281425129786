import React, { ReactElement } from "react";
import FaceImage from "./FaceImage";

interface Props {

}

export default function About({ }: Props): ReactElement {
  return (
    <div>
      <div className="wrapper">
        <div className="hero row">
          <div className="col-md-7 col-xs-12">
          <p className="subheading">A little bit about me</p>
            <p className="text">
            Hi there! I'm Christophe, a Venture Builder at <a className="link" href="https://bundl.com/" target="_blank" rel="noreferrer">Bundl</a> with 15 years of experience building things online.
        </p>
            <p className="text">
            I create effective websites and marketing that connects with customers and drives sales.
        </p>
          </div>
          <div className="col-md-3 col-md-offset-2 col-xs-12 about" id="contact">
            <FaceImage />
            <p className="sidenote text">Let's get on top of your business. Get in touch <span className="emoji">👇</span> .</p>
            <a className="button" href="tel:+32 479 70 63 42" target="_blank" rel="noreferrer">+32 479 70 63 42</a>
            <a className="button" href="mailto:cvaneerdt@gmail.com" target="_blank" rel="noreferrer">E-mail</a>
            <a className="button" href="https://www.linkedin.com/in/cvaneerdt/" target="_blank" rel="noreferrer">LinkedIn</a>
          </div>
        </div>
      </div>
    </div>
  )
}

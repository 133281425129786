import React, { ReactElement } from 'react';
import { HashLink } from 'react-router-hash-link';

interface Props {}

export default function Home({}: Props): ReactElement {
  return (
    <div className="wrapper">
      <div className="hero row">
        <div className="col-md-8 col-xs-12">
          <h1 className="fadeInUp-animation">Start online in enkele simpele stappen</h1>
          <p className="subhead fadeInUp-animation">Ik help B2B KMO's uit de startblokken om online klanten te winnen en meer te verkopen met een effectieve website en doelgerichte marketing - zonder gedoe.</p>
          <a className="button primary marginbottom fadeInUp-animation" href="https://calendar.app.google/nWpdn5N4Tn1YvKDV9" id="schedule-appointment" target="_blank">Plan onze kennismaking</a>
          <a className="button marginbottom fadeInUp-animation" href="tel:+32 479 70 63 42" target="_blank" rel="noreferrer">+32 479 70 63 42</a>

        </div>
        <div className="profile-pic col-md-4 col-xs-12">
            <img src="assets/Christophe van Eerdt Product Strategist.png" alt="Christophe van Eerdt, B2B webdesign en marketing." className="fadeInUp-animation" />
        </div>
      </div>

      {/* 
      <div className="row brands">
        <div className="col-md-12 col-xs-12">
          <p className="brandshead">Bedrijven die met mij werkten in 15 jaar online</p>
          <div className="flex justify-center md:justify-center overflow-x-auto space-x-6 py-2 scrollbar-hide">
            <img src="https://relume-assets.s3.amazonaws.com/webflow-logo.svg" alt="Bundl" className="max-h-14 md:max-h-10 flex-shrink-0 opacity-50" />
            <img src="https://relume-assets.s3.amazonaws.com/relume-logo.svg" alt="Aveve" className="max-h-14 md:max-h-10 flex-shrink-0 opacity-50" />
            <img src="https://relume-assets.s3.amazonaws.com/webflow-logo.svg" alt="Maes" className="max-h-14 md:max-h-10 flex-shrink-0 opacity-50" />
            <img src="https://relume-assets.s3.amazonaws.com/relume-logo.svg" alt="RedBull" className="max-h-14 md:max-h-10 flex-shrink-0 opacity-50" />
            <img src="https://relume-assets.s3.amazonaws.com/webflow-logo.svg" alt="Bobbejaanland" className="max-h-14 md:max-h-10 flex-shrink-0 opacity-50" />
            <img src="https://relume-assets.s3.amazonaws.com/relume-logo.svg" alt="X" className="max-h-14 md:max-h-10 flex-shrink-0 opacity-50" />
          </div>
        </div>
      </div>
      <div className="row services">
        <div className="col-md-12 col-xs-12">
          <h2>Leg een sterke basis voor online succes</h2>
          <p className="subhead2">Je weet dat online aanwezig zijn essentieel is, maar het opzetten van een website en marketingstrategie is overweldigend, en digitale agentschappen zijn duur. Herken je deze uitdagingen?</p>
          <ul>
            <li>Hoe bouw ik een effectieve website?</li>
            <li>Hoe verzamel ik e-mailadressen?</li>
            <li>Hoe word ik gevonden op Google?</li>
            <li>Hoe kan ik online adverteren?</li>
          </ul>
          <p className="subhead2">Geen zorgen, ik ben hier om je te helpen!</p>
          </div>
      </div>
      <div className="row services">
        <div className="col-md-12 col-xs-12">
            <h2>Online starten zonder stress</h2>
            <p className="subhead2">Online starten hoeft niet moeilijk te zijn. Ik bouw jouw fundamenten voor online succes in enkele simpele stappen. Samen creëren we een online aanwezigheid die perfect aansluit bij jouw merk. Ik zorg voor een eenvoudige en efficiënte aanpak, zodat jij je kunt concentreren op wat je het beste doet: het runnen van je bedrijf.</p>
        </div>
      </div>
      <div className="row services">
        <div className="col-md-12 col-xs-12">
            <h2>15 jaar ervaring in het bouwen van online oplossingen</h2>
            <p className="subhead2">Met mijn 15 jaar ervaring ontwikkel ik effectieve oplossingen die jouw online aanwezigheid versterken en je helpen snel te groeien.</p>
        </div>
      </div>
      <div className="col-md-12 col-xs-12">
            <h2>Wat mijn klanten zeggen</h2>
            <p className="subhead2">Online starten doen we samen. Lees hoe ik ondernemers hielp hun online succes te lanceren.</p>
        </div>
        <div className="container mx-auto px-4">
            <div className="grid md:grid-cols-2 gap-4">
                <div className="bg-gray-100 p-6 rounded-lg shadow">
                    <div className="text-gold-500 text-lg mb-4">★★★★★</div>
                    <p className="text-gray-600 mb-4">"Onze ervaring was geweldig. Heerlijk simpel."</p>
                    <p className="font-semibold text-gray-700">Jan Jansen<br/><span className="font-normal italic text-sm text-gray-600">Duurzaamheidsmanager, ABC Bedrijf</span></p>
                </div>
                <div className="bg-gray-100 p-6 rounded-lg shadow">
                    <div className="text-gold-500 text-lg mb-4">★★★★★</div>
                    <p className="text-gray-600 mb-4">"Wij zijn gelanceerd en draaiden x eur omzet binnen y!"</p>
                    <p className="font-semibold text-gray-700">Lisa de Vries<br/><span className="font-normal italic text-sm text-gray-600">Eigenaar, XYZ Bedrijf</span></p>
                </div>
            </div>
          </div>
        <div className="col-md-12 col-xs-12">
            <h2>Contact</h2>
            <p className="subhead2">Klaar om te starten? Neem contact op voor een vrijblijvend gesprek.</p>
        </div>
      */}
    </div>
  );
}

import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

interface Props {

}

export default function UX({ }: Props): ReactElement {
  return (
    <div className="wrapper">
      <div className="hero row">
        <div className="col-md-7 col-xs-12">
          <h1>Defining {'&'} designing our online customer experience</h1>
          <p className="subheading">Case</p>
          <p className="text">How do our strategic ambitions translate to our new Aveve retail website?</p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-7 col-xs-12">
          <p className="subheading">Outcome</p>
          <ul>
            <li>Vision statement</li>
            <li>Persona's</li>
            <li>Digital brand book {'&'} design system</li>
            <li>UX {'&'} UI design</li>
            <li>KPI framework</li>
            <li>Content strategy</li>
            <li>SEO strategy</li>
          </ul>
        </div>
        <div className="col-md-7 col-xs-12">
          <p className="subheading">Remote Process</p>
          <ul>
            <li>Discovery</li>
            <li>Stakeholder interviews</li>
            <li>Data analysis</li>
            <li>Digital brand direction</li>
            <li>Customer and target audience interviews</li>
            <li>Customer journey mapping</li>
            <li>User flows, wireframing {'&'} sitemapping</li>
            <li>Prototyping</li>
            <li>User testing</li>
            <li>UI design</li>
          </ul>
        </div>
        <div className="col-md-7 col-xs-12">
          <p className=" subheading">Role: Digital coordinator</p>
          <p className="text">Tasks:
            <ul>
              <li>Stakeholder interviews</li>
              <li>Requirements gathering</li>
              <li>Partner briefing</li>
              <li>Insights gathering</li>
              <li>Co-creation</li>
              <li>Deliverable signoff</li>
            </ul>
          </p>
        </div>

        <div className="section">
          <div className="col-md-8 col-xs-12">
            <h2>Too much process, not enough insights {'&'} decisions? I agree.</h2>
            <p className="text">I’m not at liberty to share specific project details in this portfolio. I'd be happy to elaborate on the challenge, process, insights, decisions, outcomes and next steps when we meet..</p>
            <HashLink to="/about#contact" className="button">Contact me</HashLink>
          </div>
        </div>

      </div>
    </div>
  )
}

import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link'; // Unused import

interface Props {
  // Potentially add props here if needed
}

export default function Header({ }: Props): ReactElement {
  return (
    <header className="site-header">
      <div className="wrapper site-header__wrapper">
        <div className="brand">
          <Link to="/">Christophe&nbsp;<span>van Eerdt👋</span></Link>
        </div>
        <nav className="flex nav">
          <ul className="nav__wrapper">
            {/* Uncomment if needed
            <li className="nav__item"><Link to="/about">About me</Link></li>
            */}
          </ul>
        </nav>
      </div>
    </header>
  );
}

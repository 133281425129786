import React, { ReactElement, useState, useCallback, useEffect, useRef } from "react";
import classNames from "classnames";

interface Props { }

interface Coord {
  x: number;
  y: number;
}

enum Dir {
  TL, T, TR, L, C, R, BL, B, BR
}

const calculateFace = (mouse: Coord, position: Coord): Dir => {
  const lx = mouse.x < position.x;
  const rx = mouse.x >= position.x + 250;
  const ty = mouse.y < position.y;
  const by = mouse.y >= position.y + 250;

  if (ty) {
    if (lx) return Dir.TL;
    if (rx) return Dir.TR;
    return Dir.T;
  }

  if (by) {
    if (lx) return Dir.BL;
    if (rx) return Dir.BR;
    return Dir.B;
  }

  if (lx) return Dir.L;
  if (rx) return Dir.R;
  return Dir.C;
}

export default function FaceImage({ }: Props): ReactElement {
  const [position, setPosition] = useState<Coord>({ x: 0, y: 0 });
  const [face, setFace] = useState<Dir>(Dir.C);

  // Using ref prevents rerendering on every mouse move.
  const mouse = useRef<Coord>({ x: 0, y: 0 });

  const divElement = useRef<HTMLDivElement>(null);

  const handleMouseMove = useCallback((event: MouseEvent) => {
    mouse.current = { x: event.clientX, y: event.clientY };

    const nextFace = calculateFace(mouse.current, position);

    if (nextFace !== face) {
      setFace(nextFace);
    }

  }, [mouse.current]);

  const handleResize = useCallback(_event => {
    if (divElement && divElement.current) {
      const domCords = divElement.current.getBoundingClientRect();

      setPosition({
        x: domCords.x,
        y: domCords.y
      });
    }
  }, []);

  useEffect(() => {
    if (divElement && divElement.current) {
      const domCords = divElement.current.getBoundingClientRect();

      setPosition({
        x: domCords.x,
        y: domCords.y
      });
    }

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleMouseMove, handleResize]);

  return (
    <div ref={divElement} className="face-image">
      <img className={classNames({ active: face === Dir.TL })} src="./assets/upleft.jpg" />
      <img className={classNames({ active: face === Dir.T })} src="./assets/up.jpg" />
      <img className={classNames({ active: face === Dir.TR })} src="./assets/upright.jpg" />
      <img className={classNames({ active: face === Dir.L })} src="./assets/left.jpg" />
      <img className={classNames({ active: face === Dir.C })} src="./assets/front.jpg" />
      <img className={classNames({ active: face === Dir.R })} src="./assets/right.jpg" />
      <img className={classNames({ active: face === Dir.BL })} src="./assets/downleft.jpg" />
      <img className={classNames({ active: face === Dir.B })} src="./assets/down.jpg" />
      <img className={classNames({ active: face === Dir.BR })} src="./assets/downright.jpg" />
    </div>
  )
}